.navbar {
  .nav-menu {
    font-family: Graebenbach;
    &.active {
      text-decoration: underline;
    }
  }

  .nav-menu-mobile {
    position: fixed;
    height: 100vh;
    background: rgba(255, 255, 255, 0.874);

    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .nav-menu-items {
      color: black !important;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
}
