@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.magazine {
  @include media-breakpoint-down(md) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
  }
}
