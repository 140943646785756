@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.questionnaire {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-down(md) {
    min-height: 62vh;
  }
}

.questionnaire-sub-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
