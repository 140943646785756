@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.main-image {
  position: relative;
  min-height: 70vh;

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../public/landing_page.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    background-blend-mode: multiply;
    opacity: 1;

    @include media-breakpoint-down(lg) {
      background-image: url("../../public/landing_page_mobile.jpg");
      background-position-x: center;
      background-position-y: center;
    }
  }

  @include media-breakpoint-down(lg) {
    button {
      // margin-top: -12rem;
    }
  }
}
