.multi-select-buttons-group {
  .select-button {
    position: relative;

    img {
      max-width: 12rem;
    }

    label {
      border: 1px solid black;
      text-align: center;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      padding: 4px;
      width: auto;
      cursor: pointer;
    }

    input:checked + label {
      color: rgb(255, 255, 255);
      background-color: #000000;
      &:hover,
      &:focus,
      &:active {
        background-color: #000000;
        color: rgb(255, 255, 255);
      }
    }

    input {
      opacity: 0;
      position: absolute;
    }

    .button-select {
      margin: 10px 10px 0;
      display: flex;
      background-color: transparent;
      color: rgb(0, 0, 0);

      &:hover,
      &:focus,
      &:active {
        color: rgb(0, 0, 0);
        background-color: transparent;
      }
    }
  }
}
