@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.press {
  // border: 1px solid white;
  min-height: 84vh;
}

// .page-curve {
//   min-height: 86vh;
//   background-image: url("../../public/page_curve_white.png");
//   background-repeat: no-repeat;
//   background-size: 12%;
//   background-position-x: 100.6%;
//   background-position-y: 101.6%;
//   background-blend-mode: multiply;
//   background-clip: padding-box;

//   @include media-breakpoint-down(md) {
//     background-size: 28%;
//     background-position-x: 101%;
//     background-position-y: 101%;
//   }
// }

.press-image {
  height: 24rem !important;
  width: 100%;
  object-fit: cover;
  border: 1px solid;
  // object-fit: cover;
  border: 1px solid black;

  @include media-breakpoint-up(md) {
    // max-height: 55vh;
    // max-height: 18rem;
  }
}
