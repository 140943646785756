@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.project-card-img {
  max-width: 100%;
//   height: auto;
  height: 32rem;
  width: 100%;
  object-fit: cover;
  object-position: center;

  @include media-breakpoint-down(md) {
    height: auto;
  }
}
