@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.side-selector-position {
  position: relative; // Default position for all screen sizes

  @include media-breakpoint-up(md) {
    // top: 6rem;
    z-index: 1;
    position: absolute; // Change position to absolute for md and larger screens
  }
}

.side-platform-selector {
  @include media-breakpoint-down(md) {
    position: absolute;
    // right: 0;
    top: -5.5rem;
    display: flex;
    justify-content: flex-end;
  }
}
