@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.base-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    // min-height: 90vh;
  }
}
