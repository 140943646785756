@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.about {
  .our-services-image-container {
    img {
      object-fit: cover;
      height: 58vh;
    }

    @include media-breakpoint-down(md) {
      height: auto;
      img {
        object-fit: cover;
        height: 26vh;
      }
    }
  }
}
