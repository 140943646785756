/* PDFViewerModal.css */
.pdf-viewer-modal {
  width: 100%; /* Set the width to 100% */
  max-width: 100%; /* Ensure it doesn't exceed the container width */
}

.pdf-pages {
  display: flex;
}

/* Add any additional styling as needed */
