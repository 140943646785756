@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.form {
  display: flex;
  justify-content: center;

  form {
    text-align: center;
  }

  textarea {
    border-radius: 42px;
    background-color: #f7f7f7;
    width: 100%;
  }

  select {
    border: none;
    background: transparent;
    border-bottom: 1px solid white;
    padding: 14px 6px;
    max-width: 28rem;
    border-radius: 0;
    text-align: left;
    font-size: 22px;
    font-family: "Didot";
    font-weight: bold;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: black;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: black;
    }
  }

  input {
    max-width: 100%;
    // max-width: 28rem;
    border: none;
    background: transparent;
    border-bottom: 1px solid;
    border-radius: 0;
    text-align: left;
    font-size: 22px;
    padding: 12px;
    font-family: "Didot";
    font-weight: bold;
    color: black;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: black;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: black;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      // width: 75vw;
      font-size: 18px;
    }

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  button {
    border: 1px solid;
    border-radius: 20px;
  }
}
