@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.social-media-links {
  display: flex;
  flex-direction: row;

  @include media-breakpoint-down(md) {
    justify-content: space-between;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}
