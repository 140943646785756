@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.form {
  display: flex;
  justify-content: center;

  form {
    text-align: center;
  }

  input {
    max-width: 28rem;
    border-radius: 32px;
    font-size: 24px;
    border: 1px solid gray;
    background: #f7f7f7;
    opacity: 0.8;
    padding: 12px;
    text-align: center;
    font-family: TiemposFine;
    font-weight: lighter;
    font-style: italic;

    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: black;
      font-family: TiemposFine;
      font-weight: lighter;
      font-style: italic;
      font-size: 20pt;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: black;
      opacity: 1;
      font-family: TiemposFine;
      font-weight: lighter;
      font-style: italic;
      font-size: 20pt;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: black;
      opacity: 1;
      font-family: TiemposFine;
      font-weight: lighter;
      font-style: italic;
      font-size: 20pt;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: black;
      font-family: TiemposFine;
      font-weight: lighter;
      font-style: italic;
      font-size: 20pt;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: black;
      font-family: TiemposFine;
      font-weight: lighter;
      font-style: italic;
      font-size: 20pt;
    }

    &::placeholder {
      /* Most modern browsers support this now. */
      color: black;
      font-family: TiemposFine;
      font-weight: lighter;
      font-style: italic;
      font-size: 20pt;
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: red;
      font-family: TiemposFine;
      font-weight: lighter;
      font-style: italic;
      font-size: 20pt;
    }

    @include media-breakpoint-down(md) {
      width: 92vw;
    }

    @include media-breakpoint-up(md) {
      width: 22rem;
    }
  }
}
