@font-face {
  font-family: "TiemposFine";
  src: url(../fonts/TiemposFine-LightItalic.otf) format("otf");
}

@font-face {
  font-family: "Graebenbach";
  src: url(../fonts/Graebenbach-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Graebenbach ITALIC";
  src: url(../fonts/Graebenbach-RegularItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Didot";
  src: url(../fonts/Didot.ttc) format("ttc");
}

@font-face {
  font-family: "Didot ITALIC";
  font-style: italic;
  src: url(../fonts/Didot.ttc) format("ttc");
  font-style: italic;
}

@font-face {
  font-family: "Didot BOLD";
  font-weight: bold;
  src: url(../fonts/Didot.ttc) format("ttc");
}

@font-face {
  font-family: "DidotMedium";
  src: url(../fonts/Didot-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Didot LT STD BOLD";
  font-weight: bold;
  src: url("../fonts/Didot HTF L06 Light.otf") format("otf");
}

@font-face {
  font-family: "Didot LT STD";
  src: url("../fonts/Didot HTF L06 Light.otf") format("otf");
}

@font-face {
  font-family: "Compagnon";
  src: url(../fonts/Compagnon-Light.otf) format("otf");
}

@font-face {
  font-family: "CompagnonLight";
  src: url(../fonts/Compagnon-Light.woff) format("woff");
}

@font-face {
  font-family: "Big Caslon";
  src: url(../fonts/BigCaslon.ttf) format("truetype");
}

.font-graebenbach {
  font-family: Graebenbach;
}

.font-tiemposfine-light-italic {
  font-family: TiemposFine;
  font-weight: lighter;
  font-style: italic;
}

.font-graebenbach-italic {
  font-family: Graebenbach;
  font-style: italic;
}

.font-didot {
  font-family: Didot;
}

.font-didot-italic {
  font-family: Didot;
  font-style: italic;
}

.font-didot-medium {
  font-family: DidotMedium;
}

.font-didot-bold {
  font-family: Didot;
  font-weight: bold;
}
// .gra{
//   font-family: Didot LT STD BOLD;
// }
// .gra{
//   font-family: Didot LT STD;
// }

.font-compagnon {
  font-family: Compagnon;
}

.font-compagnon-light {
  font-family: CompagnonLight;
  font-weight: lighter;
}

.font-big-caslon {
  font-family: Big Caslon;
}
