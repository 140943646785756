@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.home {
  .our-services {
    p {
      @include media-breakpoint-down(md) {
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        width: 40vw;
      }
    }
  }

  button {
    width: 12rem;
    border: 1px solid;
    border-radius: 24px;
  }

  .our-services-image-container {
    height: 100%;
    object-fit: fill;
  }
}
