@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// // 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
// @import "bootstrap/scss/functions";

// // 2. Include any default variable overrides here

// // 3. Include remainder of required Bootstrap stylesheets
// @import "bootstrap/scss/variables";

// // 4. Include any default map overrides here

// // 5. Include remainder of required parts
// @import "bootstrap/scss/maps";
// @import "bootstrap/scss/mixins";
// @import "bootstrap/scss/root";

// // 6. Optionally include any other parts as needed
// @import "bootstrap/scss/utilities";
// @import "bootstrap/scss/reboot";
// @import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/containers";
// @import "bootstrap/scss/grid";
// @import "bootstrap/scss/helpers";

// // 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
// @import "bootstrap/scss/utilities/api";

// 8. Add additional custom code here

@import "./colors.scss";
@import "./fonts.scss";
@import "./variables.scss";
@import "./navbar.scss";

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  // background: $orange;
  // color: white;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

hr {
  height: 3px;
}

* {
  box-sizing: border-box;
  // font-family: "Poppins", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
}

.brand-logo {
  width: 100%; /* 100% of viewport width */
  // height: 100vh; /* 100% of viewport height */
  display: flex;
  // align-items: center;
  justify-content: center;
  text-align: center;
  // overflow: hidden;
  margin: 0;

  img {
    width: 100%;
  }

  &.full-width-text {
    font-family: Big Caslon;
    // margin: 0;
    // font-size: 11.8vw; /* Set font size relative to viewport width */

    // @include media-breakpoint-down(lg) {
    //   font-size: 11.8vw;
    // }

    // @include media-breakpoint-down(md) {
    //   font-size: 20.5vw;
    //   line-height: 80%;
    // }

    // @include media-breakpoint-down(sm) {
    //   font-size: 19.6vw;
    //   line-height: 80%;
    // }
  }
}

.brand-logo-x {
  position: absolute;
  top: 4rem;
  right: 4rem;
  margin: 0;
  font-family: Big Caslon;
  // font-style: italic;
  font-size: 4rem;

  @include media-breakpoint-down(md) {
    font-size: 2.3rem;
    top: 5.8rem;
    right: 2rem;
  }
}

.submit-button {
  color: black;
  border: 1px solid;
  border-radius: 20px;
}

button {
  background: #ffffffcc;
  color: black;
  border: 1px solid;
  border-radius: 24px;
}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.733);
}

.react-pdf__Page__textContent.textLayer {
  display: none;
}

.react-pdf__Page__annotations.annotationLayer {
  display: none;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.react-pdf__message.react-pdf__message--error {
  color: black;
}

.validated-input {
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url(https://img.icons8.com/emoji/48/check-mark-emoji.png) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
