@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.questionnaire {
  .logout-btn {
    cursor: pointer;
    position: absolute;

    @include media-breakpoint-down(md) {
      right: 1rem;
    }

    @include media-breakpoint-up(md) {
      right: -2rem;
      top: 2.5rem;
    }
  }

  .home-btn {
    cursor: pointer;
    position: absolute;

    @include media-breakpoint-down(md) {
      left: 1rem;
    }

    @include media-breakpoint-up(md) {
      left: -1rem;
      top: 2.5rem;
    }
  }
}
